<template>
    <div class="wrap-track" :data-id="id">
        <img class="img-prev" :src="img" :data-id="id">
        <span class="text-track" :data-id="id">{{ author }}: {{ title }}</span>
    </div>
</template>

<script>
    export default {
        name: "TrackPlayList",
        props: ['id', 'title', 'author', 'audio', 'img']
    }
</script>

<style scoped>
    .img-prev {
        width: 48px;
        height: 48px;
    }
    .text-track {
        margin-left: 10px;
        color: #fff;
        z-index: 10;
    }
    .wrap-track {
        margin: 10px;
        width: 100%;
        z-index: 10;

        padding: 10px 10px 10px 20px;
        background-color: rgba(255, 255, 255, 0.125);
        height: 68px;
        border-radius: 10px;
    }

    .wrap-track:hover {
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 10px;
        z-index: 0;
        cursor: pointer;
    }
</style>