import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import store from '@/store';
import axios from 'axios';

axios.defaults.baseURL = 'https://api.pepetube.ru/';
axios.defaults.headers = {
    "Content-Type": "multipart/form-data",
    "Authorization": ""
};

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://b93013a92a9a4524b753828c21af1824@o1399975.ingest.sentry.io/4504963002269696",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["pepetube.ru", ],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.use(router).use(store).mount('#app');
