import axios from 'axios';

function fetchTrack(store) {
    return new Promise(function () {
        axios.get(
            '/player/track/',
            {
                params: {
                    'playlist': store.state.valueForFilterPlayList.map(elm => elm.id)
                }
            }
        ).then(result => {
            store.commit('addTrackList', result.data);
        }).catch(result => alert(result));
    });
}

function updateFilterPlaylist(store) {
    return new Promise(function () {
        axios.get('/player/playlist/').then(result => {
            store.commit('storeFilterPlayList', result.data);
        }).catch(result => alert(result));
    });
}

const playerModule = {
    state () {
        return {
            playList: [],
            filterPlayList: [],
            valueForFilterPlayList: []
        }
    },
    mutations: {
        addTrackList(state, data) {
          state.playList = data;
        },
        storeFilterPlayList(state, data) {
            state.filterPlayList = data;
        },
        changeFilterPlayList(state, data) {
            state.valueForFilterPlayList = data;
        }
    },
    actions: {
        fetchTrack,
        updateFilterPlaylist,
    },
    getters: {

    }
}

export default playerModule;