import axios from "axios";
import router from '@/router'

axios.interceptors.response.use(response => {
   return response;
}, error => {
    if (error.response.status === 401) {
        refreshToken(window.store, window.store.state.coreModule.refreshToken).catch(result => {
            if (error.response.status === 401 || error.response.status === 400) {
                window.store.commit('noAuthenticated');
                router.push('/');
            } else {
                alert(result);
            }
        });
    }
  return error;
});

function fetchToken(store, username, password, code) {
    return new Promise(function () {
        axios.post(
            '/o/token/',
            {
                'grant_type': 'password',
                'username': username,
                'password': password,
                'client_id': '3dfPBAq4YjNAXCr1Y1blrEvFE5mDrieb6kAq5MhK',
                'code': code
            }
        ).then(result => {
            store.commit(
                'storeToken', {
                    access: result.data['access_token'],
                    refresh: result.data['refresh_token']
                }
            );
            testToken(store);
        }).catch(
            result => alert(result)
        );
    });
}

function refreshToken(store, refresh_token) {
    return new Promise(function (resolve, reject) {
        axios.post(
            '/o/token/',
            {
                'grant_type': 'refresh_token',
                'refresh_token': refresh_token,
                'client_id': '3dfPBAq4YjNAXCr1Y1blrEvFE5mDrieb6kAq5MhK'
            }
        ).then(result => {
            store.commit(
                'storeToken', {
                    access: result.data['access_token'],
                    refresh: result.data['refresh_token']
                }
            );
            resolve(result);
        }).catch(
            result => reject(result)
        );
    });
}

function testToken(store) {
    return new Promise(function (resolve, reject) {
        axios.get('/player/echo/').then(
            () => {
                resolve();
            }
        ).catch(
            result => {
                reject();
                store.isAuthenticated = false;
                alert(result)
            }
        );
    });
}

function storeOnLocalStorageAuth() {
    window.localStorage.accessToken = window.store.state.coreModule.accessToken;
    window.localStorage.refreshToken = window.store.state.coreModule.refreshToken;
    window.localStorage.isAuthenticated = window.store.state.coreModule.isAuthenticated;
    window.localStorage.username = window.store.state.coreModule.username;
}

function loadFromLocalStorageAuth() {
    if (window.localStorage.accessToken) {
        window.store.commit('saveLogin', window.localStorage.username);
        window.store.commit('storeToken', {
            access: window.localStorage.accessToken,
            refresh: window.localStorage.refreshToken
        });
    }
}

const coreModule = {
    state() {
        return {
            accessToken: null,
            refreshToken: null,
            isAuthenticated: null,
            username: null,
        }
    },
    mutations: {
        storeToken(state, {access, refresh}) {
            state.accessToken = access;
            state.refreshToken = refresh;
            state.isAuthenticated = state.accessToken != null;
            axios.defaults.headers['Authorization'] = `Bearer ${access}`;
            storeOnLocalStorageAuth();
        },
        saveLogin(state, data) {
            state.username = data;
        },
        noAuthenticated(state) {
            state.accessToken = "";
            state.refreshToken = "";
            state.username = "";
            state.isAuthenticated = false;
            storeOnLocalStorageAuth();
        },
    },
    actions: {
        signIn(store, {password, code}) {
            fetchToken(store, store.state.username, password, code);
        },
        initAuth(store) {
            loadFromLocalStorageAuth(store);
        }
    },
    getters: {
        isAuthenticated: (state) => {
            return state.isAuthenticated;
        }
    }
}

export default coreModule;