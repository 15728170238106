import { createWebHistory, createRouter } from "vue-router";
import BunnyPlayer from "@/views/BunnyPlayer";
import ImportMusic from "@/views/ImportMusic";
import SignIn from "@/views/SignIn";

const routes = [
  {
    path: "/",
    name: "login",
    component: SignIn,
  },
  {
    path: "/bunny-player",
    name: "bunny-player",
    component: BunnyPlayer,
  },
  {
    path: "/import-music",
    name: "import-music",
    component: ImportMusic,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;