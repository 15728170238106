<template>
    <div class="background"></div>
    <div id="nav" class="nav" v-if="isAuthenticated">
        <img class="logo" src="favicon">
        <div class="wrapper-route">
            <router-link to="/bunny-player" class="nav-link active">
                <img class="nav-icon" src="icons/play-alt-svgrepo-com.svg">
            </router-link>
            <router-link to="/import-music" class="nav-link">
                <img class="nav-icon" src="icons/arrow-up-circle-svgrepo-com.svg">
            </router-link>
        </div>
    </div>
    <router-view />
</template>

<script>
    export default {
        name: 'App',
        created() {
            this.$store.dispatch('initAuth');
        },
        computed: {
            isAuthenticated() {
                return this.$store.getters.isAuthenticated;
            }
        }
    }
</script>

<style>
    #app {

    }

    #nav {
        background-color: black;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    #nav > .wrapper-route {
        height: 50px;
        margin-left: 20px;
    }

    .wrapper-route > a {
        display: inline-block;
    }

    .background {
        background-color: rgb(3, 3, 3);
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    .nav-icon {
        width: 32px;
    }

    .nav-icon:hover {
        background-color: rgba(255, 255, 255, 0.75);
        transform: scale(1.25);
        border-radius: 10px;
    }

    .logo {
        width: 48px;
        height: 32px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 20px;
    }
</style>
