<template>
    <div class="center" v-if="isNoAuthenticated">
        <div>
            <input type="text" v-model="login" placeholder="Логин">
        </div>
        <div>
            <input type="password" v-model="password" placeholder="Пароль">
        </div>
        <div>
            <input type="text" v-model="code" placeholder="OTP">
        </div>
        <button @click="signIn">Войти</button>
    </div>
</template>

<script>
    export default {
        name: "SignIn",
        data() {
            return {
                login: '',
                password: '',
                code: '',
            }
        },
        methods: {
            signIn: function () {
                this.$store.commit('saveLogin', this.login);
                this.$store.dispatch(
                    'signIn',
                    {
                        password: this.password,
                        code: this.code
                    }
                );
            }
        },
        computed: {
            isNoAuthenticated() {
                return !this.$store.getters.isAuthenticated;
            }
        }
    }
</script>

<style scoped>
    .center {
        position: absolute;
        top: 25%;
        width: 100%;
        text-align: center;
    }

    .center div {
        margin: 5px;
    }

    .center input {
        background-color: #555555;
    }

    input[type=text], input[type=password] {
        color: #ffffff;
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.15);
        border-radius: 4px;
        box-sizing: border-box;
    }

    button {
        border-radius: 5px;
        background-color: #555555;
        border: none;
        color: white;
        padding: 5px 100px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    }
</style>