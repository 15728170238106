import {createStore} from "vuex";
import playerModule from "@/store/player"
import coreModule from "@/store/core"

const store = createStore({
    modules: {
        playerStore: playerModule,
        coreModule: coreModule
    }
})

window.store = store;

export default store;