<template>

    <div class="playlist-selector">
      <VueMultiselect
        v-model="filterForPlaylist"
        :options="filterPlaylist"
        :multiple="true"
        :close-on-select="false"
        placeholder="Плейлист"
        label="title"
        track-by="title"
        :limit="1"
      />
  </div>

  <div class="play-list">
    <track-play-list
        v-for="track in audioList"
        :key="track.id"
        :title="track.title"
        :author="track.author"
        :audio="track.audio"
        :id="track.id"
        :img="track.img"
        :class="{choose: track.id === currentIdTrack}"
        v-on:click="onClickTrack"
    ></track-play-list>
  </div>

  <div class="footer-player">
    <div class="background-player">
      <div class="wrapper-player">
        <div class="name-track">{{ currentAudioName }}</div>
        <audio-player
          ref="audioPlayer"
          :show-playback-rate=true
          :audio-list="audioList.map(elm => elm.audio)"
          :before-play="handleBeforePlay"
          theme-color="#fff"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import AudioPlayer from '@liripeng/vue-audio-player'
  import TrackPlayList from '../components/TrackPlayList.vue'
  import VueMultiselect from 'vue-multiselect'

  export default {
    name: 'BunnyPlayer',
    components: {
        AudioPlayer,
        TrackPlayList,
        VueMultiselect
    },
    created() {
      this.$store.dispatch('fetchTrack');
      this.$store.dispatch('updateFilterPlaylist');
    },
    data() {
        return {
            currentAudioName: '',
            currentIdTrack: null,
            value: [],
        }
    },
    computed: {
      audioList() {
        return this.$store.state.playerStore.playList;
      },
      filterPlaylist() {
        return this.$store.state.playerStore.filterPlayList;
      },
      filterForPlaylist: {
        get: function () {
          return this.$store.state.playerStore.valueForFilterPlayList;
        },

        set: function (newValue) {
          this.$store.commit('changeFilterPlayList', newValue);
          this.$store.dispatch('fetchTrack');
        }
      }
    },
    methods: {
      onClickTrack(event) {
        let idTrack = parseInt(event.target.getAttribute('data-id'));

        let BreakException = {};

        try {
          this.audioList.forEach((track, indexNum) => {
            if (idTrack === track.id) {
              this.$refs.audioPlayer.clearTimer();
              this.$refs.audioPlayer.currentPlayIndex = indexNum;
              this.$refs.audioPlayer.$nextTick(() => {
                this.$refs.audioPlayer.play()
                this.$refs.audioPlayer.$emit('play-next')
              })
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
      },
      handleBeforePlay(next) {
        let currentAudio = this.audioList[this.$refs.audioPlayer.currentPlayIndex];
        this.currentAudioName = currentAudio.title;
        this.currentIdTrack = currentAudio.id;

        document.title = `BM: ${this.currentAudioName}`;

        if ('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: this.currentAudioName,
             artwork: [{
                src: currentAudio.img,
                sizes: "96x96",
                type: "image/png",
              },
             ]
          });
          navigator.mediaSession.setActionHandler('play', () => {
            navigator.mediaSession.playbackState = "playing";
            this.$refs.audioPlayer.play();
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            navigator.mediaSession.playbackState = "paused";
            this.$refs.audioPlayer.pause();
          });
          navigator.mediaSession.setActionHandler('previoustrack', this.$refs.audioPlayer.playPrev);
          navigator.mediaSession.setActionHandler('nexttrack', this.$refs.audioPlayer.playNext);
        }

        next();
      },
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
  .playlist-selector {
    max-width: 40%;
    min-width: 40%;
    max-height: 16px !important;
    position: fixed;
    right: 10px;
    top: 5px;
    z-index: 1000;
    transform: scale(0.85);
  }

  .footer-player {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: auto;
  }

  .choose {
    background-color: rgba(0, 255, 0, 0.275) !important;
  }

  .background-player {
    background-color: rgba(0, 0, 0, 0.96);
    width: auto;
    height: 160px;
  }

  .wrapper-player {
    position: fixed;
    width: 90%;
    left: 5%;
    bottom: 5%;
    z-index: 10000;
  }

  .audio__btn-wrap {
    justify-content: left !important;
    display: inline-block;
  }

  .audio__play-start, .audio__play-pause{
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .audio__play-volume-icon-wrap {
    margin-left: 30px !important;
  }

  .name-track {
    color: #fff;
    display: flex;
    justify-content: right;
    /*margin-bottom: -30px;*/
    overflow: hidden;
  }

  .play-list {
    width: 90%;
    margin: 60px auto 150px;
  }
</style>
